import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { INavLink } from '../types/contentfulContentTypes';
import normalizePathname from '../utils/normalizePathname';

export interface NavLinkProps {
  link: INavLink;
  [attributes: string]: any;
}

// NavLink is used to determine which type of link to output based on the CMS data passed to it.
// It also applies any other attributes applied to it where it is imported (ex key, className...)
const NavLink: React.FC<NavLinkProps> = (props) => {
  const { children, link, ...attributes } = props;
  // If the CMS data contains an external URL vs page reference, it checks to see if we want it to open in a new tab and outputs an <a> tag
  if (link.linkUrl) {
    const targetProperties = link.newTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};
    return (
      <a href={`${link.linkUrl}`} {...targetProperties} {...attributes}>
        {children}
      </a>
    );
    // If the CMS data contains a page reference, it outputs a <GatsbyLink> instead
  } else if (link.linkPage?.pageSlug) {
    return (
      <GatsbyLink
        to={normalizePathname(link.linkPage?.pageSlug)}
        {...attributes}
      >
        {children}
      </GatsbyLink>
    );
  } else {
    // If broken link
    return (
      <span title="This link is broken" {...attributes}>
        {children}
      </span>
    );
  }
};

export default NavLink;

import React, { ReactElement } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse, { Element as HTMLReactParserElement } from 'html-react-parser';
import placeholderImage from '../assets/images/placeholder.png';
import { IMediaAsset } from '../types/contentfulContentTypes';

interface ContentfulImageProps {
  image?: Maybe<IMediaAsset | string | (() => any)>;
  [attributes: string]: any;
}
/**
 * This component takes an image of type ContentfulAsset and determines
 * whether it should use the GatsbyImage element for .pngs, .jpgs, etc.,
 * or a standard <img /> element for .svgs.
 *
 * The GraphQL query must contain either image.gatsbyImageData for
 * static images or image.file.contentType and image.file.url if it is
 * an SVG.
 */
const ContentfulImage: React.FC<ContentfulImageProps> = (props) => {
  const { image, ...attributes } = props;
  const isReactElement =
    typeof image === 'function' && React.isValidElement(image());
  const isSvg =
    typeof image === 'object' && image?.file?.contentType === 'image/svg+xml';

  if (!image) {
    // broken image
    return <img src={placeholderImage} alt="Placeholder" {...attributes} />;
  } else if (isReactElement) {
    const ReactElement = image as React.FC;
    return <ReactElement {...attributes} />;
  } else if (typeof image === 'string') {
    return <img src={image} alt={``} {...attributes} />;
  } else if (isSvg) {
    if (image?.svg && image?.svg.content) {
      // Inlined SVGs
      return parse(image?.svg.content, {
        replace: (domNode) => {
          const element = domNode as HTMLReactParserElement;
          if (element.parent === null) {
            element.attribs = {
              ...(element.attribs || {}),
              ...attributes,
            };
          }
          return element;
        },
      }) as ReactElement<any, any>;
    }
    // SVGs that can/should not be inlined
    return (
      <img
        src={image?.file?.url}
        alt={image?.description ?? ''}
        {...attributes}
      />
    );
  } else if (
    typeof image === 'object' &&
    image &&
    image.gatsbyImageData !== undefined
  ) {
    const gatsbyImage = getImage(image.gatsbyImageData);
    return (
      <GatsbyImage
        image={gatsbyImage!}
        alt={image?.description ?? ''}
        {...attributes}
      />
    );
  } else {
    // broken image
    return <img src={placeholderImage} alt="Placeholder" {...attributes} />;
  }
};
export default ContentfulImage;

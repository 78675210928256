import { Link } from 'gatsby';
import styled from 'styled-components';

import twTheme from '../../styles/theme';

export const MainHeader = styled.header`
  position: absolute;
  width: 100%;
  background-color: ${twTheme.colour.black};
  z-index: 103;

  /* === */ /* For scroll effect */
  &.scroll-down {
    transform: translate3d(0, -100%, 0);
  }
  &.homePage,
  &.standardPage {
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    background-color: ${twTheme.colour.black};
    backdrop-filter: blur(10px);
    transition: all 300ms ease-in-out;
  }
`;

export const LogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  &.standardPage {
    width: 60%;
  }

  &.standardPage,
  &.homePage {
    flex-wrap: nowrap;
    width: 60%;
    height: auto;

    @media screen and (max-width: 374px) {
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    &.standardPage,
    &.homePage {
      flex-wrap: nowrap;
      width: 75%;
      height: auto;
    }
  }
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    &.standardPage,
    &.homePage {
      flex-wrap: nowrap;
      height: auto;
      width: 50%;
    }
  }
`;

export const DefaultLogo = styled(Link)`
  display: block;
  max-width: 133px;
  margin-right: 30px;

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    height: auto;
    max-width: 176px;
  }
`;

export const NavBar = styled.nav`
  display: none;
  justify-content: flex-end;
  align-items: center;
  width: 55%;
  padding-top: 8px;
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    display: inline-flex;
    gap: 30px;
  }
`;

export const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    display: none;
  }
`;

export const HeaderContentWrapper = styled.div`
  max-width: ${twTheme.screens.maxViewport};
  margin: auto;
  position: relative;
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: ${twTheme.spacing.sm};
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    &.homePage,
    &.standardPage {
      padding: ${twTheme.spacing.sm} ${twTheme.spacing.md};
    }
  }

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    padding: ${twTheme.spacing.sm} ${twTheme.spacing.lg};
  }
`;

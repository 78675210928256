import twTheme from './theme';

// Determine page or section level background colour from CMS data passed in
// "Transparent" value is only available on section in CMS, and will allow page background colour to appear behind a section

const determineBackgroundColour = (cmsTheme: string): object => {
  const pageStyles: { [key: string]: string } = {};
  switch (cmsTheme) {
    case 'forest':
      pageStyles['backgroundColor'] = twTheme.colour.forest;
      break;
    case 'emerald':
      pageStyles['backgroundColor'] = twTheme.colour.emerald;
      break;
    case 'tangerine':
      pageStyles['backgroundColor'] = twTheme.colour.tangerine;
      break;
    case 'mint':
      pageStyles['backgroundColor'] = twTheme.colour.mint;
      break;
    case 'lilac':
      pageStyles['backgroundColor'] = twTheme.colour.lilac;
      break;
    case 'cream':
      pageStyles['backgroundColor'] = twTheme.colour.cream;
      break;
    case 'white':
      pageStyles['backgroundColor'] = twTheme.colour.white;
      break;
    case 'black':
      pageStyles['backgroundColor'] = twTheme.colour.black;
      break;
    case 'lightGrey':
      pageStyles['backgroundColor'] = twTheme.colour.lightGrey;
      break;
    case 'darkGrey':
      pageStyles['backgroundColor'] = twTheme.colour.darkGrey;
      break;
    case 'transparent':
      pageStyles['backgroundColor'] = 'transparent';
      break;
    case 'gradientDark1':
      pageStyles['backgroundImage'] = twTheme.colour.gradientDark1;
      break;
    case 'gradientDark2':
      pageStyles['backgroundImage'] = twTheme.colour.gradientDark2;
      break;
    case 'gradientLight1':
      pageStyles['backgroundImage'] = twTheme.colour.gradientLight1;
      break;
    case 'gradientLight2':
      pageStyles['backgroundImage'] = twTheme.colour.gradientLight2;
      break;
    case 'gradientCool':
      pageStyles['backgroundImage'] = twTheme.colour.gradientCool;
      break;
    case 'gradientWarm':
      pageStyles['backgroundImage'] = twTheme.colour.gradientWarm;
      break;
    default:
      pageStyles['backgroundColor'] = twTheme.colour.white;
  }
  return pageStyles;
};

export default determineBackgroundColour;

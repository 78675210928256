import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import animatedLogoLight from '../../assets/images/animated-tw-logo-light.json';
import MenuIcon from '../UI/MenuIcon';
import MobileMenu from '../UI/MobileMenu';

import {
  MainHeader,
  NavBar,
  LogoWrapper,
  DefaultLogo,
  MenuIconWrapper,
  HeaderContentWrapper,
} from './styles';
import { INavMenu } from '../../types/contentfulContentTypes';
import NavItem from './NavItem';
import SubNavItem from './SubNavItem';

export interface HeaderProps {
  data: {
    mainLogo: object;
    navigationMenus: INavMenu[];
    sloganText: object;
  };
  isHomePage: boolean;
  currentPath: string;
  mobileLogoColour: 'light' | 'dark';
}

const Header: React.FC<HeaderProps> = ({ data, isHomePage, currentPath }) => {
  // Mobile menu status
  const [isMenuOpen, updateIsMenuOpen] = useState(false);
  // Pop in/out scroll behaviour for header
  const [scrollClassName, setScrollClassName] = useState('');
  // Close all subnavs on navigate
  const [navigated, setNavigated] = useState(false);
  // Controls if they displayMenu component is mounted or not.
  const [displayMenu, setDisplayMenu] = useState(false);

  // Ref is used to fix edge case where a very quick open > close > open would see the menu close but the icon reflect an open menu
  const isMenuOpenRef = useRef(isMenuOpen);
  const handleMenuOpen = () => {
    if (!isMenuOpen) {
      isMenuOpenRef.current = true;
      setDisplayMenu(true);
      updateIsMenuOpen(!isMenuOpen);
    } else {
      isMenuOpenRef.current = false;
      updateIsMenuOpen(!isMenuOpen);
      setTimeout(() => {
        if (!isMenuOpenRef.current) setDisplayMenu(false);
      }, 500);
    }
  };

  const handleNavItemClick = () => {
    setNavigated(true);
    setTimeout(() => {
      setNavigated(false);
    }, 500);
  };

  // Create nav items from CMS data
  const navItems = data.navigationMenus[0].navLinks?.map((navItem) =>
    navItem.hasSubNavigation === 'Yes' ? (
      <SubNavItem
        key={navItem.id}
        navItem={navItem}
        currentPath={currentPath}
        hide={!!scrollClassName || navigated}
      />
    ) : (
      <NavItem
        key={navItem.id}
        navItem={navItem}
        currentPath={currentPath}
        onClick={handleNavItemClick}
      />
    ),
  );

  useEffect(() => {
    let lastScroll = 0;
    const scrollListener = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        setScrollClassName('');
        return;
      }
      if (currentScroll > lastScroll) {
        setScrollClassName('scroll-down');
      } else if (currentScroll < lastScroll) {
        setScrollClassName('');
      }
      lastScroll = currentScroll;
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  // This ref is used in the mobile menu focus trap to include it in the tab order
  const headerHamburgerRef = useRef(null);

  // This ref is used in the mobile menu logo to include it in the tab order
  const headerLogoRef = useRef(null);
  return (
    <>
      <MainHeader
        className={`${
          isHomePage ? 'homePage' : 'standardPage'
        } ${scrollClassName} ${isMenuOpen ? 'menuOpen' : ''}`}
      >
        <HeaderContentWrapper>
          {/* Logo */}
          <LogoWrapper className={isHomePage ? 'homePage' : 'standardPage'}>
            <DefaultLogo
              to={'/'}
              onClick={() => updateIsMenuOpen(false)}
              ref={headerLogoRef}
            >
              <span className="sr-only">Thrillworks</span>
              <Lottie animationData={animatedLogoLight} loop={false} />
            </DefaultLogo>
          </LogoWrapper>

          {/* Nav Menu */}
          <NavBar>{navItems}</NavBar>
          <MenuIconWrapper ref={headerHamburgerRef}>
            <MenuIcon
              menuStatus={isMenuOpen ? 'menuOpen' : ''}
              clickHandler={handleMenuOpen}
            />
          </MenuIconWrapper>
        </HeaderContentWrapper>
      </MainHeader>

      {/* Mobile menu */}
      {displayMenu && (
        <MobileMenu
          currentPath={currentPath}
          isMenuOpen={isMenuOpen}
          clickHandler={handleMenuOpen}
          focusTrapRef={headerHamburgerRef}
          logoRef={headerLogoRef}
        />
      )}
    </>
  );
};

export default Header;

import { PageProps, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageContext } from '../../types/pageContext';
import Header from '../Header';
import { INavLink } from '../../types/contentfulContentTypes';
import {
  ContentfulRichText,
  renderContentfulRichText,
} from '../../utils/renderContentfulRichText';
import Footer from '../Footer';
import determineBackgroundColour from '../../styles/backgroundColour';
import { useState, useLayoutEffect, createContext } from 'react';

// The Layout component is implemented on every page automatically via wrapPageElement in gatsby-browser.
export const JobOpeningsContext = createContext(0);

const Layout: React.FC<PageProps<any, PageContext>> = (props) => {
  const {
    children,
    data: { contentfulV2Page },
  } = props;

  //Layout Query
  const layoutData: Queries.LayoutDataQuery = useStaticQuery(graphql`
    query LayoutData {
      contentfulV2Footer {
        logoImage {
          ...AssetFragment
        }
        logoNavLink {
          ...NavLinkFragment
        }
        navigationText {
          raw
        }
        phoneNumber
        addressText
        navLinks {
          ...NavLinkFragment
        }
        legalLinks {
          ...NavLinkFragment
        }
        copyrightText
      }
      contentfulV2Header(contentful_id: { eq: "5j8tHlPhp0uJJU76I1z8Sp" }) {
        mainLogo {
          linkUrl
          newTab
          icon {
            file {
              url
            }
            gatsbyImageData(placeholder: BLURRED)
            description
          }
        }
        sloganText {
          raw
        }
        navigationMenus {
          navLinks {
            ...NavLinkFragment
          }
        }
      }
      contentfulV2NavMenu(contentful_id: { eq: "4kDACJO1Zxbk78L7CzT4ZZ" }) {
        navLinks {
          ...NavLinkFragment
        }
      }
    }
  `);

  //Map footer
  const footerPhoneNumberText = layoutData?.contentfulV2Footer
    ?.phoneNumber as string;
  const footerAddressText = layoutData?.contentfulV2Footer
    ?.addressText as string;
  const footerLogoNavLink = layoutData?.contentfulV2Footer
    ?.logoNavLink as INavLink;
  const footerNavigationText = renderContentfulRichText(
    layoutData?.contentfulV2Footer?.navigationText as ContentfulRichText,
  );
  const footerNavLinks = layoutData?.contentfulV2Footer?.navLinks as INavLink[];
  const footerLegalLinks = layoutData?.contentfulV2Footer
    ?.legalLinks as INavLink[];

  // Get total # of job postings from BambooHR for use across site
  const [totalJobOpenings, setTotalJobOpenings] = useState(0);
  const getJobPostings = async () => {
    let rawJson = [];
    try {
      rawJson = await fetch(
        'https://api.lever.co/v0/postings/thrillworks/?mode=json',
      ).then((response) => response.json());
      setTotalJobOpenings(rawJson.length);
    } catch (err) {
      //If we fail, position data stays an empty array with 0 jobs
      return;
    }
  };
  useLayoutEffect(() => {
    getJobPostings();
  }, [totalJobOpenings]);

  // Standard pages have background colours applied via the CMS, and use the util func to return the correct CSS code
  // Case Studies are always 'white' and have no CMS value to read
  // Articles are always 'gradientLight1' and have no CMS value to read
  const backgroundValue = () => {
    if (Object.keys(props.data).includes('contentfulV2Page')) {
      return determineBackgroundColour(contentfulV2Page.theme);
    } else if (
      Object.keys(props.data).includes('allContentfulV2CaseStudyPage')
    ) {
      return determineBackgroundColour('white');
    } else if (
      Object.keys(props.data).includes('allContentfulV2ThinkingArticle')
    ) {
      return determineBackgroundColour('gradientLight1');
    }
  };

  // Determine page type (standard page, article or case study) to pull the correct CMS data in for TW logo colour
  const mobileLogoColour = () => {
    if (Object.keys(props.data).includes('contentfulV2Page')) {
      return props.data.contentfulV2Page.mobileLogoColour;
    } else if (
      Object.keys(props.data).includes('allContentfulV2CaseStudyPage')
    ) {
      return props.data.allContentfulV2CaseStudyPage.nodes[0].mobileLogoColour;
    } else if (
      Object.keys(props.data).includes('allContentfulV2ThinkingArticle')
    ) {
      return props.data.allContentfulV2ThinkingArticle.nodes[0]
        .mobileLogoColour;
    }
  };

  return (
    <>
      <Header
        data={layoutData.contentfulV2Header as any}
        isHomePage={props.location.pathname === '/' && true}
        currentPath={props.location.pathname}
        mobileLogoColour={mobileLogoColour()}
      />
      <main id="content" style={backgroundValue()}>
        <JobOpeningsContext.Provider value={totalJobOpenings}>
          {children}
        </JobOpeningsContext.Provider>
      </main>
      <Footer
        footerLogoNavLink={footerLogoNavLink}
        phoneNumber={footerPhoneNumberText}
        addressText={footerAddressText}
        navigationText={footerNavigationText}
        navLinks={footerNavLinks}
        legalLinks={footerLegalLinks}
        hideOnDesktop={props.location.pathname.includes('contact')}
      />
    </>
  );
};

export default Layout;
